import React, { useState } from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./singleProductHeader.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faMinus,
  faPlus
} from '@fortawesome/free-solid-svg-icons'
import { useAddItemsToCart } from '@jamal./gatsby-theme-shopify-manager';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { AnchorLink } from "gatsby-plugin-anchor-links";

// import Swiper core and required modules
import SwiperCore, { Pagination } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import 'swiper/swiper.scss';
import 'swiper/css'
// import 'swiper/components/pagination/pagination.scss';
import 'swiper/css/pagination'

// install Swiper modules
SwiperCore.use([Pagination]);

export default function SingleProductHeader(props) {

  const addItemsToCart = useAddItemsToCart();

  const product = props.data.product;
  const variantArrayData = props.data.variants;


  // Swiper instance
  const [swiper, setSwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  const [quantity, setQuantity] = useState(1);


  // Params definition
  let params = {
    loop: false,
    spaceBetween: 0,
    pagination: true
  };


  async function handleAddToCart(event) {
    event.preventDefault()
    const variantId = props.data.activeVariantId;
    const quantityVal = quantity;

    const items = [
      {
        variantId: variantId,
        quantity: quantityVal,
      },
    ];

    try {
      // await addItemsToCart(items);
      alert('Produkt derzeit nicht verfügbar.');
    } catch {
      alert('There was a problem adding that item to your cart.');
    }

    // document.getElementsByClassName("cartContentBG")[0].classList.toggle('is-cart-open');
    // document.body.classList.add("body-lock");
    // document.getElementById("cartOverlay").classList.toggle('is-cart-open');
  }

  function handleThumbClick(e) {
    setSwiperIndex(parseInt(e.currentTarget.dataset.index))
    swiper.slideTo(e.currentTarget.dataset.index)
  }

  function increaseQuantity(e) {
    setQuantity(quantity+1)
  }

  function decreaseQuantity(e) {
    setQuantity(quantity-1)
  }

  function handleSlideChange(e) {
    setSwiperIndex(e.realIndex);
  }

  return (
    <div>
	    <div className={styles.productHeader}>
        <Container className={styles.innerProductHeader}>
          <Row className="align-items-start d-flex justify-content-space-around">
            <Col lg={1} className={styles.thumbnailBox}>
              <div className={`d-none d-md-flex flex-column`}>
                {props.data.productThumbnails.edges[0].node.images.map((image, key) => (
                  <div key={key} role="button" tabIndex={key} className={`d-block px-lg-0 mw-100`} data-index={key} onClick={handleThumbClick} onKeyDown={null}>
                    <GatsbyImage
                      className={`d-md-block ${key === swiperIndex ? styles.activeThumbnail : styles.inactiveThumbnail} mb-3`}
                      image={image.localFile.childImageSharp.gatsbyImageData}
                      loading="eager"
                      key={image.id}
                      alt={`${product.title} ${key}`}
                    />
                  </div>
                ))}
              </div>
            </Col>
            <Col lg={5} className={styles.galleryBox}>
              <Swiper {...params} pagination={{ clickable: true, clickableClass: `${styles.paginationClickable}` }} onSlideChange={handleSlideChange} onSwiper={(s) => {
                setSwiper(s);
              }}>
                {product.edges[0].node.images.map((image, key) => (
                  <SwiperSlide key={key}><GatsbyImage
                    className={`${styles.oCover} h-100 w-100`}
                    image={image.localFile.childImageSharp.gatsbyImageData}
                    loading="eager"
                    alt={`${product.title} ${key}`}
                  /></SwiperSlide>
                ))}
                <div className={styles.sliderTitle}>{props.data.sliderTitle}</div>
              </Swiper>
            </Col>
            <Col lg={6} className={styles.productInfo}>
              <h2>{props.data.titleAbove}</h2>
              <h1>{props.data.title}</h1>
              <div className={`${styles.reviewSummary} d-block w-100`}>
                <div className="text-center mx-auto d-flex align-items-center">
                  <div className={`${styles.summaryStars}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      {props.reviewRating >= 1.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {props.reviewRating >= 2.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {props.reviewRating >= 3.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {props.reviewRating >= 4.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                  </div>
                  <div className={`${styles.summaryCount}`}>
                    <AnchorLink to={`${window.location.pathname+'#reviews'}`} className="f-13" style={{textDecoration: 'none', color: '#172345'}}>{props.reviewCount} <span>Bewertungen</span></AnchorLink>
                  </div>
                </div>
              </div>
              {props.data.description}
              <div className={`${styles.keyFeatures} w-100 mb-5`}>
                <ul className="mb-0 d-inline-block text-left">
                  {props.data.keypoints.map(function (item, index) {
                    return (<li><FontAwesomeIcon icon={faCheck} className="mr-2 text-black small" /> <span><span className={index+1 === props.data.keypoints.length ? 'fw-bold' : null}>{item}</span></span></li>)
                  })}
                </ul>
              </div>
                <>
                  <p className={styles.yourSelection}>Deine Auswahl:</p>
                  <div className={styles.selectionBox}>
                    {product.edges[0].node.options[0].values.length > 1 ?
                      <div className={`${styles.variantSelect}`}>
                        {product.edges[0].node.options[0].values.map((value, k) => (
                          <div role="button" tabIndex={k} className={`${styles.swatchElementText} ${k === props.fluoridStatus ? styles.checked : null}`} data-key={k} data-value={value} onFocus={ () => void 0 } onBlur={ () => void 0 } onClick={props.handleFluoridSelect} onKeyDown={null}>
                            <div className={`text-center`}>
                              <div role="button" tabIndex={0}>{value}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    : null }
                    {props.data.showDetails ?
                      <div className={`d-flex align-items-start mb-1 ${product.edges[0].node.options[0].values.length > 1 ? null : styles.mobileTopSpace}`}>
                        <div className={styles.dotContainer}><div className={styles.greenDot}><span></span></div></div>
                        <div>
                          <p className="mb-0"><span className="fw-bold">{props.data.name}</span> enthält folgende Produkte</p>
                          <ul>
                            {props.data.keypointsShort.map(function (item, index) {
                              return (<li>1x {item}</li>)
                            })}
                          </ul>
                        </div>
                      </div>
                      : null
                    }
                    <div className={styles.priceInfoBox}>
                      {(variantArrayData[props.data.activeVariantKeyString].compareAtPrice !== "" && variantArrayData[props.data.activeVariantKeyString].compareAtPrice !== null) &&
                        <span className={styles.strikePrice}><s>{variantArrayData[props.data.activeVariantKeyString].compareAtPrice.replace('.', ',')}€</s></span>
                      }
                      <span className={styles.finalPrice}>{variantArrayData[props.data.activeVariantKeyString].price.toFixed(2).toString().replace('.', ',')}€</span>
                      {(variantArrayData[props.data.activeVariantKeyString].compareAtPrice !== "" && variantArrayData[props.data.activeVariantKeyString].compareAtPrice !== null) &&
                        <span className={styles.discountText}>(Spare {props.data.name === "Probierpaket" ? '25%' : '20%'})</span>
                      }
                    </div>
                    <div className={styles.deliveryInfo}>
                      <div className="d-flex">
                        <svg data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 40.74" width="30" height="20"><defs></defs><title>Schnelle Lieferung</title><path stroke="black" stroke-width="2" className="cls-1" d="M71.26,29.05,64.7,19.93a.88.88,0,0,0-.72-.37H53.52L55,7.84a1.54,1.54,0,0,0-1.54-1.76H2.32a.9.9,0,0,0,0,1.79h50.9l-2.89,22.6H15.67L16.93,20H21a.9.9,0,0,0,0-1.79H3.27a.9.9,0,1,0,0,1.79H15.14L12.73,40a1.6,1.6,0,0,0,.37,1.25,1.56,1.56,0,0,0,1.17.52H18.7a5.22,5.22,0,0,0,1.3,3.3,5.29,5.29,0,0,0,4.05,1.77,6.89,6.89,0,0,0,6.46-5.07H49.14a1.61,1.61,0,0,0,.44-.06.87.87,0,0,0,.32.06H53a5.26,5.26,0,0,0,1.29,3.3,5.33,5.33,0,0,0,4.06,1.77,6.89,6.89,0,0,0,6.46-5.07h4.26A.89.89,0,0,0,70,41l1.45-11.29A.88.88,0,0,0,71.26,29.05ZM28.89,40.74A5,5,0,0,1,24.05,45a3.58,3.58,0,0,1-2.72-1.17,3.64,3.64,0,0,1-.82-2.9,5,5,0,0,1,4.84-4.3,3.56,3.56,0,0,1,2.72,1.17A3.61,3.61,0,0,1,28.89,40.74ZM49.6,36.17,49.11,40H30.71a5.27,5.27,0,0,0-1.3-3.3,5.33,5.33,0,0,0-4.06-1.77A6.89,6.89,0,0,0,18.89,40H14.53l.93-7.71H50.1Zm13.61,4.57A5,5,0,0,1,58.37,45a3.58,3.58,0,0,1-2.72-1.17,3.63,3.63,0,0,1-.82-2.9,5,5,0,0,1,4.84-4.3,3.47,3.47,0,0,1,3.54,4.07ZM68.3,40H65a5.27,5.27,0,0,0-1.3-3.3,5.33,5.33,0,0,0-4.06-1.77A6.89,6.89,0,0,0,53.21,40h-2.3l1-7.71H69.29Zm1.22-9.49H52.13l1.17-9.13H63.52L69.6,29.8Z" transform="translate(-1.43 -6.08)"/><path className="cls-1" d="M28,13.59a.9.9,0,0,0,0-1.79H6.51a.9.9,0,1,0,0,1.79Z" transform="translate(-1.43 -6.08)"/></svg>
                        <span className={styles.deliverySpeedLabel}>Kostenloser Versand</span>
                      </div>
                    </div>
                    {props.data.showSelection
                      ? <p className={styles.infoBox}>Deine Auswahl enthält {quantity*20} Drops {props.fluoridStatus === 0 ? 'mit Fluorid' : 'ohne Fluorid'}. Jede Person kann damit 10 Tage lang Zähne putzen.</p>
                      : null
                    }
                  </div>
                </>
              <div className={styles.atcBox}>
                <div className={styles.personStepper}>
                  <div className={`d-block`}>
                    <button data-type="minus" type="button" className={`${styles.minusStep} btn-number bg-white d-flex align-items-center justify-content-center m-0 decreaseQuantity ${quantity === 1 ? 'disabled' : ''}`} onClick={decreaseQuantity} onKeyDown={null} disabled={quantity === 1 ? 'disabled' : null}>
                      <FontAwesomeIcon icon={faMinus} className={`f-11`} />
                    </button>
                  </div>
                  <input max="4" min="1" type="text" className="text-center border-0 f-17 mb-0 d-inline-block p-0 form-control input-number d-block mb-0 cartCountSelector" value={quantity} disabled />
                  <div className="d-block">
                    <button data-type="minus" type="button" className={`${styles.plusStep} btn-number bg-white d-flex align-items-center justify-content-center m-0 increaseQuantity`} onClick={increaseQuantity} onKeyDown={null}>
                      <FontAwesomeIcon icon={faPlus} className="f-11" />
                    </button>
                  </div>
                </div>
                <div role="button" tabIndex={0} className={styles.atcBtn} onClick={handleAddToCart} onKeyDown={null}>
                  <div className={styles.atcText}>
                    In den Warenkorb
                  </div>
                  <div className={styles.atcPrice}>
                    <span className={styles.finalPrice}>{variantArrayData[props.data.activeVariantKeyString].price.toFixed(2).toString().replace('.', ',')}€</span>
                    {(variantArrayData[props.data.activeVariantKeyString].compareAtPrice !== "" && variantArrayData[props.data.activeVariantKeyString].compareAtPrice !== null) &&
                      <span className={styles.strikePrice}><s>{variantArrayData[props.data.activeVariantKeyString].compareAtPrice.replace('.', ',')}€</s></span>
                    }
                  </div>
                </div>
              </div>
            </Col>
            <div id={styles.mobileButtonArea} className="d-block bg-white d-md-none pt-0">
							<div className="text-center d-block py-1 border-top w-100"></div>
							<div className="px-3 pb-2 pb-md-0 px-md-0">
								<a href="#atc" onClick={handleAddToCart} onKeyDown={null} className={`${styles.bottomBarAtc} f-17 w-100 btn btn-primary btn-lg font-weight-bold atc-btn d-inline-flex align-items-center justify-content-center mint`}>
  								<span>
  									Jetzt kaufen — {variantArrayData[props.data.activeVariantKeyString].price.toFixed(2).toString().replace('.', ',')}€
                    {(variantArrayData[props.data.activeVariantKeyString].compareAtPrice !== "" && variantArrayData[props.data.activeVariantKeyString].compareAtPrice !== null) &&
  									  <s className="ml-1 o-5 font-weight-normal small">{variantArrayData[props.data.activeVariantKeyString].compareAtPrice.replace('.', ',')}€</s>
                    }
                  </span>
								</a>
								<div className="w-100 text-center">
									<div className="w-100 f-10 mt-1">
										{variantArrayData[props.data.activeVariantKeyString].productTitle} {variantArrayData[props.data.activeVariantKeyString].title !== 'Default Title' ? variantArrayData[props.data.activeVariantKeyString].title : ''}<span className="mx-2">•</span>Gratis Versand{variantArrayData[props.data.activeVariantKeyString].basePrice !== 0 ? <><span className="mx-2">•</span>{variantArrayData[props.data.activeVariantKeyString].basePrice.toString().replace('.',',')} € / Stück</> : null}
									</div>
								</div>
							</div>
						</div>
          </Row>
        </Container>
      </div>
		</div>
  )
}
