// extracted by mini-css-extract-plugin
export var productHeader = "singleProductHeader-module--product-header--4kivP";
export var innerProductHeader = "singleProductHeader-module--inner-product-header--7Knsi";
export var thumbnailBox = "singleProductHeader-module--thumbnail-box--TYo1j";
export var activeThumbnail = "singleProductHeader-module--active-thumbnail--c-0YC";
export var inactiveThumbnail = "singleProductHeader-module--inactive-thumbnail--XGinn";
export var galleryBox = "singleProductHeader-module--gallery-box--7ciX7";
export var oCover = "singleProductHeader-module--o-cover--6GOm0";
export var paginationClickable = "singleProductHeader-module--pagination-clickable--Cv4YE";
export var sliderTitle = "singleProductHeader-module--slider-title--3Ji-G";
export var productInfo = "singleProductHeader-module--product-info--q0xSs";
export var reviewSummary = "singleProductHeader-module--review-summary--E6LyG";
export var summaryStars = "singleProductHeader-module--summary-stars--S4w3X";
export var starRating = "singleProductHeader-module--star-rating--rLnnH";
export var ratingIndicator = "singleProductHeader-module--rating-indicator--sLK5x";
export var stars = "singleProductHeader-module--stars--Wy8RV";
export var summaryCount = "singleProductHeader-module--summary-count--f7o5r";
export var keyFeatures = "singleProductHeader-module--key-features--aSA19";
export var yourSelection = "singleProductHeader-module--your-selection--2XEUm";
export var selectionBox = "singleProductHeader-module--selection-box--XcqAW";
export var variantSelect = "singleProductHeader-module--variant-select--Y75LT";
export var swatchElementText = "singleProductHeader-module--swatch-element-text--qDRvO";
export var checked = "singleProductHeader-module--checked--0BkKF";
export var dotContainer = "singleProductHeader-module--dot-container--kQHyL";
export var greenDot = "singleProductHeader-module--green-dot--qH112";
export var priceInfoBox = "singleProductHeader-module--price-info-box--REBq3";
export var strikePrice = "singleProductHeader-module--strike-price--5D7LJ";
export var finalPrice = "singleProductHeader-module--final-price--2VQPB";
export var discountText = "singleProductHeader-module--discount-text--R5kYf";
export var deliveryInfo = "singleProductHeader-module--delivery-info--b+uuO";
export var deliverySpeedLabel = "singleProductHeader-module--delivery-speed-label--S7GDR";
export var infoBox = "singleProductHeader-module--info-box--12zN8";
export var atcBox = "singleProductHeader-module--atc-box--8kzZB";
export var personStepper = "singleProductHeader-module--person-stepper--kyF19";
export var minusStep = "singleProductHeader-module--minus-step--szoBK";
export var plusStep = "singleProductHeader-module--plus-step--+m8mm";
export var atcBtn = "singleProductHeader-module--atc-btn--lFFgG";
export var atcText = "singleProductHeader-module--atc-text--S1+Tj";
export var atcPrice = "singleProductHeader-module--atc-price--oFu-T";
export var otherDiscount = "singleProductHeader-module--other-discount--tWzM9";
export var waBtn = "singleProductHeader-module--wa-btn--bILwz";
export var waInfotext = "singleProductHeader-module--wa-infotext--VO1jm";
export var mobileTopSpace = "singleProductHeader-module--mobileTopSpace--B22js";
export var mobileButtonArea = "singleProductHeader-module--mobile-button-area---UApI";
export var bottomBarAtc = "singleProductHeader-module--bottom-bar-atc--GKhcr";