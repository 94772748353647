import React, { useState, useEffect } from "react"
import Layout from "../../components/layout/layout"

import { useStaticQuery, graphql } from "gatsby"

import SingleProductHeader from "../../components/product/singleProductHeader"
import SloganSection from "../../components/product/sloganSection"
import KeyfactsSection from "../../components/product/keyfactsSection"
import IngredientsSection from "../../components/product/ingredientsSection"
import HowtoSection from "../../components/product/howtoSection"
import IconSection from "../../components/index/iconSection"
import ValuepropSection from "../../components/product/valuepropSection"
import NoplasticSection from "../../components/product/noplasticSection"
import PriceExplanation from "../../components/product/priceExplanation"
import DeliverySection from "../../components/product/deliverySection"
import TrySection from "../../components/product/trySection"
import AllReviews from "../../components/reviews/allReviews"
import FaqSection from "../../components/product/faqSection"
import CustomersBought from "../../components/product/customersBoughtSection"
import CookieConsent from "react-cookie-consent";

import { sortImages } from '../../utils/helper'

import useIsClient from '../../useIsClient';

import Seo from "../../components/layout/seo";

export default function Monatspaket() {

  const { isClient, key } = useIsClient();

  const data = useStaticQuery(graphql`
    query {
      allShopifyProduct(sort: {fields: [title]}, filter: {id: {eq: "6217d3ed-8025-54e8-a84b-bdea53a23b61"}}) {
        edges {
          node {
            title
            images {
              originalSrc
              id
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
          images {
            localFile {
              name
              childImageSharp {
                gatsbyImageData(height: 90, width: 90, placeholder: NONE, layout: FIXED)
              }
            }
          }
        }
      },
      productThumbnails: allShopifyProduct(sort: {fields: [title]}, filter: {id: {eq: "6217d3ed-8025-54e8-a84b-bdea53a23b61"}}) {
        edges {
          node {
            images {
              originalSrc
              id
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 105, width: 84, placeholder: NONE)
                }
              }
            }
          }
        }
      },
      bottom: file(relativePath: { eq: "product-header-bottom-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      },
      userImg: file(relativePath: { eq: "masculine-user.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, placeholder: NONE, height: 25)
        }
      },
      tryImg: file(relativePath: { eq: "probierpaket_try.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      refillImg: file(relativePath: { eq: "Refill_Pack_freigestellt.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      customersBought: allShopifyProduct(
        sort: {fields: [title], order: ASC}
        filter: {id: {in: ["72c83be1-b5c8-5b3d-aa9a-d4c69816c66a", "46e3acb2-5819-530c-9958-2a4e07ba522e"]}}
      ) {
        edges {
          node {
            title
            images {
              originalSrc
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 650, width: 625, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
            handle
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
        }
      },
      zubehoerReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351700848874"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      starterkitReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351701078250"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      allDropsReview(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6619176370359"]}}, rating: {gte: 1}}
        sort: {fields: createdAt, order: DESC}
      ) {
        edges {
          node {
            body
            createdAt
            files
            id
            name
            rating
            title
            productIds {
              ids
            }
          }
        }
        totalCount
        sum(field: rating)
      },
      allDropsQuestion(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6619176370359"]}}}
      ) {
        edges {
          node {
            body
            curated
            name
            title
            createdAt
            productQuestionAnswers {
              body
              createdAt
            }
          }
        }
      }
    }
  `)

  // reorder images
  data.allShopifyProduct.edges[0].node.images = sortImages('localFile.name', data.allShopifyProduct.edges[0].node.images);
  data.allShopifyProduct.nodes[0].images = sortImages('localFile.name', data.allShopifyProduct.nodes[0].images);
  data.productThumbnails.edges[0].node.images = sortImages('localFile.name', data.productThumbnails.edges[0].node.images);
  data.customersBought.edges[0].node.images = sortImages('localFile.name', data.customersBought.edges[0].node.images);
  data.customersBought.edges[0].node.reviewsCount = data.starterkitReview.totalCount;
  data.customersBought.edges[0].node.reviewsRating = (data.starterkitReview.sum/data.starterkitReview.totalCount).toFixed(1);
  data.customersBought.edges[1].node.images = sortImages('localFile.name', data.customersBought.edges[1].node.images);
  data.customersBought.edges[1].node.reviewsCount = data.zubehoerReview.totalCount;
  data.customersBought.edges[1].node.reviewsRating = (data.zubehoerReview.sum/data.zubehoerReview.totalCount).toFixed(1);

  const [variantArrayData, setVariantArrayData] = useState(null);
  const [activeVariantKeyString, setActiveVariantKeyString] = useState(data.allShopifyProduct.edges[0].node.options[0].values[0].replace(' ', '_'));
  const [activeVariantId, setActiveVariantId] = useState(data.allShopifyProduct.nodes[0].variants[0].shopifyId);
  const [fluoridStatus, setFluoridStatus] = useState(0);

  useEffect(() => {
    var variantArray = {}
    var regex = /[+-]?\d+(\.\d+)?/g;
    data.allShopifyProduct.nodes[0].variants.forEach((variant, variantKey) => {
      variantArray[variant.selectedOptions[0].value.replace(' ', '_')] = {
        productTitle: data.allShopifyProduct.edges[0].node.title,
        title: variant.title,
        price: parseFloat(variant.price),
        compareAtPrice: variant.compareAtPrice,
        shopifyId: variant.shopifyId,
        productImg: data.allShopifyProduct.nodes[0].images[0].localFile,
        tryImg: data.tryImg,
        basePrice: variant.metafields.length > 0 ? variant.metafields[2].value.replace(',','.').match(regex).map(function(v) { return parseFloat(v); })[0] : 0
      }
    });
    setVariantArrayData(variantArray);
  }, [data]);


  function handleFluoridSelect(e) {
    setFluoridStatus(parseInt(e.currentTarget.dataset.key))
    setActiveVariantKeyString(e.currentTarget.dataset.value.replace(' ', '_'))
    setActiveVariantId(variantArrayData[e.currentTarget.dataset.value.replace(' ', '_')].shopifyId)
  }

  const trySectionKeypoints = {
    left: ['20 Drops für 10 Tage', '1x Bambuszahnbürste', '1x Reisedose', 'Versandkostenfrei'],
    right: ['100% Plastikfrei', 'In den Briefkasten', 'Schnelle Lieferung', 'Weltretter Status']
  }

  const productData = {
    product: data.allShopifyProduct,
    productThumbnails: data.productThumbnails,
    variants: variantArrayData,
    activeVariantKeyString: activeVariantKeyString,
    activeVariantId: activeVariantId,
    title: 'Toothpaste Drops Minze',
    titleAbove: 'Frische Explosion',
    name: 'Probierpaket',
    description: <><p><span className="font-weight-bold">Probierpaket & kostenlose Lieferung — </span>Teste unsere innovativen Zahnpasta-Drops sorgenfrei zum Vorteilspreis und verabschiede dich endgültig von der Plastiktube.</p><p>Hergestellt mit reinen, veganen Inhaltsstoffen, sorgen unsere Zahnpasta-Drops für gesunde Zähne und eine Frische-Explosion. Zusätzlich bleiben unsere Ozeane sauber durch eine komplett plastikfreie Verpackung.</p></>,
    keypoints: ['Super frisch & schäumend', 'Wiederverwendbare Reisedose', 'Enthält Fluoride zur Kariesprävention', 'Frei von Chemikalien, Parabenen & SLS', 'Versandkostenfrei'],
    showSelection: true,
    showDetails: false,
    sliderTitle: 'Für ein dauerhaft nachhaltiges Lächeln'
  }

  let productIds = {
  	"ids": [
  		"6619176370359"
  	]
  }

  if ( !isClient ) return null;

  return (
    <Layout activeVariant={variantArrayData[activeVariantKeyString]} key={key}>
      <Seo title="Drops Refill | Natürliche Minze | Toothpaste Drops" path="/products/probierpaket" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <SingleProductHeader data={productData} fluoridStatus={fluoridStatus} handleFluoridSelect={handleFluoridSelect} reviewRating={(data.allDropsReview.sum/data.allDropsReview.totalCount).toFixed(1)} reviewCount={data.allDropsReview.edges.length} />
      <SloganSection />
      <KeyfactsSection />
      <IngredientsSection />
      <HowtoSection />
      <IconSection background={true} firstTitle="4.9" firstText={<>> 500 positive<br />Rückmeldungen</>} firstStar={true} secondTitle="0%" secondText={<>Plastik<br />innen & außen</>} thirdTitle="100%" thirdText={<>Made in Germany<br />& Made by Us</>} fourthTitle="2x" fourthText={<>alle Sorten mit und<br />ohne Fluorid</>} />
      <ValuepropSection />
      <NoplasticSection />
      <PriceExplanation />
      <DeliverySection />
      <TrySection activeVariant={variantArrayData[activeVariantKeyString]} ctaHook="kaufen" activeVariantId={activeVariantId} headline="Probierpaket" subhl={<><p>Für dein neues Zahnputzerlebnis bieten wir dir ein 100% plastikfreies <span className="fw-bold">Probierpaket für nur 8,95€</span> an.</p></>} keypoints={trySectionKeypoints} image={data.tryImg} />
      <AllReviews reviews={data.allDropsReview} questions={data.allDropsQuestion} reviewRating={(data.allDropsReview.sum/data.allDropsReview.totalCount).toFixed(1)} productIds={productIds} />
      <FaqSection />
      <CustomersBought data={data.customersBought} />
      <CookieConsent style={{ background: "#000000", color: "#ffffff", fontSize: "14px", alignItems: "center" }} contentClasses="m-2" buttonClasses="m-2" buttonStyle={{ color: "#ffffff", background: "#0275d8", fontSize: "13px" }} buttonText="OK">Zur Bereitstellung von Diensten verwenden wir Cookies. Durch die Nutzung dieser Website stimmen Sie zu.</CookieConsent>
    </Layout>
  )
}
